import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'

export default {
	black,
	white,
	primary: {
		light: '#f5ebdd',
		main: '#f79222',
		dark: '#52493b',
		contrastText: '#fff',
	},
	secondary: {
		light: '#919caf',
		main: '#636e80',
		dark: '#384354',
		contrastText: '#fff',
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: colors.green[600],
		light: colors.green[400],
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400],
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400],
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: '#52493b',
		secondary: colors.blueGrey[600],
		link: '#f79222',
	},
	background: {
		default: '#F4F6F8',
		paper: white,
	},
	icon: '#f79222',
	divider: colors.grey[200],
	action: {
		disabledBackground: colors.grey[300],
		disabled: colors.grey[600],
	},
}
