import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import { Grid, CircularProgress } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import PassengerSubHeader from './PassengerSubHeader'
import { connect } from 'react-redux'
import {
	getIsLoading,
	getOpenDialog,
	getSelectedSeats,
} from '../../redux/accessors/seats'
import {
	selectSeats,
	closeDialog,
	unselectSeats,
} from '../../redux/actions/seats'
import Payment from './payment'
import Logo from '../../static/epesiLogoWhite.png'
import { api } from '../../config/api'
import { useHistory } from 'react-router-dom'
import CancelTripDialog from './payment/canceltrip'

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	root: {
		backgroundColor: 'white',
	},
	cardGrid: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(8),
		paddingLeft: theme.spacing(10),
		paddingRight: theme.spacing(10),

		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		[theme.breakpoints.down('xs')]: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
	},
	button: {
		margin: theme.spacing(2, 0),
		width: '100%',
	},
	logo: {
		maxWidth: 160,
	},
	wrapper: {
		position: 'relative',
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const PaymentDetailsDialog = ({
	selectedSeats,
	selectSeats,
	isLoading,
	isDialogOpen,
	closeDialog,
	unselectSeats,
	trip,
}) => {
	const classes = useStyles()

	const history = useHistory()
	const [open, setOpen] = React.useState(false)
	useEffect(() => {
		console.log(
			'seats selected',
			selectedSeats.map(seat => seat),
		)
	})

	const handleClickOpen = () => {
		const seatIds = selectedSeats.map(seat => seat._id)
		//console.log("seats ", seatIds);
		selectSeats(api, seatIds)
		//setOpen(true);
	}

	const handleUnSelectSeats = async () => {
		const seatIds = selectedSeats.map(seat => seat._id)
		await unselectSeats(api, seatIds)
		history.go(-2)
	}

	return (
		<div>
			<div className={classes.wrapper}>
				<Button
					onClick={handleClickOpen}
					className={classes.button}
					disabled={!selectedSeats.length || isLoading ? true : false}
					variant="contained"
					color="primary"
				>
					CONFIRM SELECTION
				</Button>
				{isLoading && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
			</div>
			<Dialog
				fullScreen
				open={isDialogOpen}
				//onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						{/* <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton> */}
						<a href="/">
							<img className={classes.logo} src={Logo} alt="logo" />
						</a>
						<Typography variant="h6" className={classes.title}></Typography>
						<CancelTripDialog
							handleUnSelectSeats={handleUnSelectSeats}
							isLoading={isLoading}
							trip={trip}
						/>
					</Toolbar>
				</AppBar>
				{selectedSeats.length > 0 && (
					<div className={classes.root}>
						<PassengerSubHeader />
						<div className={classes.cardGrid}>
							<Grid item xs={12} lg={12}>
								<Payment
									handleUnSelectSeats={handleUnSelectSeats}
									// currentSeatModel={currentSeatModel}
								/>
							</Grid>
						</div>
					</div>
				)}
			</Dialog>
		</div>
	)
}

const mapState = state => ({
	selectedSeats: getSelectedSeats(state),
	isLoading: getIsLoading(state),
	isDialogOpen: getOpenDialog(state),
})

const mapDispatch = {
	selectSeats,
	unselectSeats,
	closeDialog,
}

export default connect(mapState, mapDispatch)(PaymentDetailsDialog)
