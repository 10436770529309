import React, { forwardRef } from 'react'
import {
	Container,
	Grid,
	Typography,
	Button,
	Card,
	CardContent,
	Avatar,
	makeStyles,
	Box,
} from '@material-ui/core'
import {
	EventSeat as EventSeatIcon,
	Schedule as ScheduleIcon,
	DirectionsBus as LocalBus,
	AttachMoney as AttachMoneyIcon,
} from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
	modalContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		outline: 'none',
	},
	modalContent: {
		backgroundColor: theme.palette.background.paper,
		borderRadius: theme.spacing(2),
		maxHeight: '80vh',
		minHeight: '30vh',
		width: '95%',
		maxWidth: '900px',
		overflow: 'auto',
		padding: theme.spacing(2),
		outline: 'none',
		position: 'relative',
	},
	root: {
		padding: theme.spacing(2),
		minHeight: 'auto',
	},
	headers2: {
		fontWeight: 800,
		fontSize: '24px',
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	tripCard: {
		marginBottom: theme.spacing(2),
		borderRadius: theme.spacing(2),
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.02)',
		},
	},
	tripCardBody: {
		width: '100%',
	},
	cardHeaderTop: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cardHeader: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	saccoLogo: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
	tripDetails: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
		marginTop: theme.spacing(2),
	},
	detailItem: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		marginRight: theme.spacing(2),
	},
}))

const RouteTrips = forwardRef(({ route }, ref) => {
	const history = useHistory()
	const classes = useStyles()

	const handleBook = tripId => () => {
		history.push(`/trip/${tripId}`)
	}

	// If no route or no trips, return null or a placeholder
	if (!route || !route.trips || route.trips.length === 0) {
		return (
			<div className={classes.modalContainer}>
				<div className={classes.modalContent}>
					<Typography variant="h6" align="center">
						No trips available for this route
					</Typography>
				</div>
			</div>
		)
	}

	return (
		<div className={classes.modalContainer} ref={ref}>
			<div className={classes.modalContent}>
				<Typography className={classes.headers2}>Available Trips</Typography>

				<Grid container spacing={2}>
					{route.trips.map((trip, index) => (
						<Grid item xs={12} key={index}>
							<Card className={classes.tripCard} elevation={3}>
								<CardContent className={classes.tripCardBody}>
									<div className={classes.cardHeaderTop}>
										<div className={classes.cardHeader}>
											<Avatar
												src={trip.saccoLogo}
												alt={`${trip.saccoName} Logo`}
												className={classes.saccoLogo}
											/>
											<div>
												<Typography variant="h6">{trip.saccoName}</Typography>
												<Typography variant="subtitle2" color="textSecondary">
													{trip.departure} to {trip.destination}
												</Typography>
											</div>
										</div>
										<Button
											variant="contained"
											color="primary"
											onClick={handleBook(trip._id)}
										>
											Book Now
										</Button>
									</div>

									<div className={classes.tripDetails}>
										<div className={classes.detailItem}>
											<ScheduleIcon color="primary" fontSize="small" />
											<Typography variant="body2">
												{moment(trip.departureTime).format('lll')}
											</Typography>
										</div>
										<div className={classes.detailItem}>
											<EventSeatIcon color="primary" fontSize="small" />
											<Typography variant="body2">
												{Number(trip.seats) - Number(trip.sold)} Seats Left
											</Typography>
										</div>
										<div className={classes.detailItem}>
											<LocalBus color="primary" fontSize="small" />
											<Typography variant="body2">{trip.departure}</Typography>
										</div>
										<div className={classes.detailItem}>
											<AttachMoneyIcon color="secondary" fontSize="small" />
											<Typography variant="body2" color="secondary">
												{trip.minPrice} KES - Standard Class
											</Typography>
										</div>
									</div>
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>
			</div>
		</div>
	)
})

export default RouteTrips
