import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Grid, Box, Divider } from '@material-ui/core'
import PrimaryLogo from '../../../static/epesiPrimary.png'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { green } from '@material-ui/core/colors'
import { getTrip, getIsLoading } from '../../../redux/accessors/trip'
import { fetchTrip } from '../../../redux/actions/trip'
import { capitalize } from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { timeFormat } from '../../../util/time'
import SelectedSeats from '../SelectedSeats'
import { fade } from '@material-ui/core/styles/colorManipulator'
import PassengerCard from '../../../components/PassengerCard'
import { getPassenger } from '../../../redux/accessors/payment'

const useStyles = makeStyles(theme => ({
	seatNoText: {
		fontSize: '16px',
		fontWeight: 600,
		color: theme.palette.secondary.dark,
		marginBottom: 20,
	},
	subTitle: {
		fontSize: '14px',
		fontWeight: 600,
		color: theme.palette.secondary.dark,
		//  marginBottom: 10,
	},
	logo: {
		maxWidth: 100,
		marginTop: 20,
	},
	title: {
		fontSize: '16px',
		fontWeight: 600,
		color: theme.palette.black,
		flexGrow: 1,
	},
	regNo: {
		fontSize: '14px',
		fontWeight: 600,
		color: theme.palette.secondary.dark,
		flexGrow: 1,
	},
	locationDiv: {
		display: 'flex',
		alignItems: 'center',
		//marginBottom:10
	},
	fromLocationIcon: {
		color: theme.palette.primary.main,
		fontSize: '18px',
		fontWeight: 900,
		marginRight: 5,
	},
	toLocationIcon: {
		color: green[700],
		fontSize: '20px',
		fontWeight: 900,
		marginRight: 4,
	},
	divider: {
		height: 20,
		width: 2,
		backgroundColor: 'none',
		marginLeft: 8,
		margin: 5,
		border: `1px dashed ${theme.palette.secondary.light}`,
	},
	pos: {
		marginLeft: 12,
		fontWeight: 500,
	},
	location: {
		paddingLeft: 10,
		marginBottom: 10,
	},
	locationText: {
		fontSize: '14px',
		fontWeight: 600,
		color: theme.palette.secondary.main,
	},
	seatsTitleText: {
		fontSize: '14px',
		fontWeight: 600,
		color: theme.palette.secondary.dark,
	},
	tripTitle: {
		backgroundColor: fade(theme.palette.secondary.light, 0.3),
		height: 30,
		borderBottom: `2px solid ${theme.palette.secondary.light}`,
		//  paddingLeft: 5,
	},
	passengerTitle: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: fade(theme.palette.secondary.light, 0.3),
		height: 30,
		borderBottom: `2px solid ${theme.palette.secondary.light}`,
		paddingLeft: 5,
	},
	gridItem: {
		padding: 5,
		marginLeft: 5,
	},
	divider: {
		marginTop: 5,
		marginBottom: 5,
		marginRight: 5,
	},
	//   fare:{
	//       display:'flex',
	//      // flexDirection:"column",
	//     alignItems:'flex-end',
	//     height:'100%'
	//   },
	//   fareSpace:{
	//       flexGrow:1
	//   }
}))

const Main = ({
	trip,
	// currentSeatModel,
	selectedSeats,
	passenger,
}) => {
	const classes = useStyles()

	console.log('passenger summary', passenger)
	return (
		<div>
			<Typography className={classes.seatNoText} gutterBottom>
				Kindly confirm your trip details
			</Typography>

			<Box
				className={classes.tripTitle}
				display="flex"
				alignItems="center"
				p={1}
				m={0}
				//bgcolor="background.paper"
				//css={{ height: 100 }}
			>
				<Typography className={classes.subTitle}>Trip summary</Typography>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
					<div className={classes.gridItem}>
						<img className={classes.logo} src={PrimaryLogo} alt="logo" />
						<Typography
							component="div"
							className={classes.title}
							gutterBottom
							//align="center"
						>
							<Box> {capitalize(trip.saccoName)}</Box>{' '}
						</Typography>
						<Typography
							component="div"
							className={classes.regNo}
							//align="center"
						>
							<Box> {trip.vehicleno}</Box>{' '}
						</Typography>
						<Divider className={classes.divider} />
						<Typography
							className={classes.dateTime}
							color="textSecondary"
							gutterBottom
						>
							{moment(trip.departureTime).format(timeFormat)}
						</Typography>
						<div className={classes.locationDiv}>
							<LocationSearchingIcon className={classes.fromLocationIcon} />{' '}
							<Typography
								component="div"
								className={classes.locationText}
								//align="center"
							>
								<Box lineHeight={1.3}> {capitalize(trip.departure)}</Box>{' '}
							</Typography>
						</div>
						<Divider
							orientation="vertical"
							flexItem
							className={classes.divider}
						/>
						<div className={classes.locationDiv}>
							<LocationOnIcon className={classes.toLocationIcon} />{' '}
							<Typography
								component="div"
								className={classes.locationText}
								//align="center"
							>
								<Box lineHeight={1.3}> {capitalize(trip.destination)}</Box>{' '}
							</Typography>
						</div>
					</div>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={6}
					className={classes.fare}
				>
					<div className={classes.gridItem}>
						<div className={classes.fareSpace}></div>
						<Typography
							component="div"
							className={classes.seatsTitleText}
							//align="center"
						>
							<Box lineHeight={1.3}> Selected seats</Box>{' '}
						</Typography>
						<SelectedSeats
							buttonVisible={false}
							// currentSeatModel={currentSeatModel}
							selectedSeats={selectedSeats}
							paperVisible={false}
						/>
					</div>
				</Grid>
			</Grid>

			<Box
				className={classes.passengerTitle}
				className={classes.tripTitle}
				display="flex"
				alignItems="center"
				p={1}
				m={0}
				//bgcolor="background.paper"
				//css={{ height: 100 }}
			>
				<Typography className={classes.subTitle}>
					Passenger details summary
				</Typography>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<PassengerCard passenger={passenger} />
				</Grid>
			</Grid>
		</div>
	)
}

const mapState = state => ({
	trip: getTrip(state),
	passenger: getPassenger(state),
	isLoading: getIsLoading(state),
})

const mapDispatch = {
	fetchTrip,
}

export default connect(mapState, mapDispatch)(Main)
