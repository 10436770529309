import React from 'react'
import {
	Button,
	Card,
	CardActions,
	CardContent,
	CardActionArea,
	Grid,
	Typography,
	Container,
	CardHeader,
	Avatar,
	Box,
	Divider,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import moment from 'moment'
import { red, green } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import { capitalize } from 'lodash'
import { getTrips, getIsLoading } from '../../redux/accessors/search'
import { timeFormat } from '../../util/time'
import { motion } from 'framer-motion'
import LocationSearchingIcon from '@material-ui/icons/LocationSearching'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Rating from '@material-ui/lab/Rating'
import PrimaryLogo from '../../static/epesiPrimary.png'
import NoBusFound from '../../components/NoBusFound'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
	cardGrid: {
		backgroundColor: 'white',
		paddingTop: theme.spacing(5),
		paddingBottom: theme.spacing(8),
		width: '100%',
		maxWidth: '100%',
		margin: 0,
		padding: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(4),
		},
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(1),
		},
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
	},
	avatar: {
		backgroundColor: red[500],
	},
	cardContent: {
		flexGrow: 1,
	},
	pos: {
		//marginBottom: 12,
	},

	actionArea: {
		'&:hover $focusHighlight': {
			opacity: 0,
		},
	},
	focusHighlight: {},
	title: {
		fontSize: '16px',
		fontWeight: 600,
		color: theme.palette.black,
	},
	location: {
		paddingLeft: 10,
	},
	locationText: {
		fontSize: '16px',
		fontWeight: 600,
		color: theme.palette.secondary.main,
	},
	locationDiv: {
		display: 'flex',
		alignItems: 'center',
		//marginBottom:10
	},
	fromLocationIcon: {
		color: theme.palette.primary.main,
		fontSize: '18px',
		fontWeight: 900,
		marginRight: 5,
	},
	toLocationIcon: {
		color: green[700],
		fontSize: '20px',
		fontWeight: 900,
		marginRight: 4,
	},
	divider: {
		height: 20,
		width: 2,
		backgroundColor: 'none',
		marginLeft: 8,
		margin: 5,
		border: `1px dashed ${theme.palette.secondary.light}`,
	},
	lineDivider: {
		marginBottom: 10,
	},
	header: {
		height: 50,
		[theme.breakpoints.down('xs')]: {
			height: 50,
		},
	},
	fareText: {
		fontSize: '22px',
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	seatsText: {
		fontSize: '14px',
		//color:theme.palette.primary.main,
		//fontWeight:600
	},
	fareSection: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		flexDirection: 'column',
	},
	logo: {
		maxWidth: 100,
	},
}))

const formatNum = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const Main = ({ trips, isLoading }) => {
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.down('xs'))

	const cardVariants = {
		rest: {
			y: 0,
			borderRadius: '5px',
			transition: {
				duration: 0.2,
				type: 'tween',
				ease: 'easeInOut',
			},
		},
		hover: {
			y: -5,
			borderBottom: `5px solid ${theme.palette.primary.main}`,
			borderRight: `5px solid ${theme.palette.primary.main}`,
			backgroundColor: theme.palette.primary.main,
			borderRadius: '5px',
			cursor: 'pointer',
			transition: {
				duration: 0.2,
				type: 'tween',
				ease: 'easeInOut',
			},
		},
	}

	return (
		<Container className={classes.cardGrid}>
			<Grid container spacing={2}>
				{!trips.length && !isLoading ? (
					<NoBusFound />
				) : (
					trips.map(trip => (
						<Grid key={trip._id} item xs={12} sm={12} md={12} lg={12} xl={12}>
							<motion.div
								initial="rest"
								whileHover="hover"
								animate="rest"
								variants={cardVariants}
							>
								<Card className={classes.card}>
									<CardActionArea
										href={`/trip/${trip._id}`}
										classes={{
											root: classes.actionArea,
											focusHighlight: classes.focusHighlight,
										}}
									>
										<CardHeader
											avatar={
												<img
													className={classes.logo}
													src={PrimaryLogo}
													alt="logo"
												/>
											}
											title={capitalize(trip.saccoName)}
											classes={{
												title: classes.title,
												root: classes.header,
											}}
											subheader={
												<Typography
													className={classes.pos}
													color="textSecondary"
												>
													{moment(trip.departureTime).format(timeFormat)}
												</Typography>
											}
										/>

										<CardContent className={classes.cardContent}>
											{matches && <Divider className={classes.lineDivider} />}
											<Grid container spacing={2}>
												<Grid
													item
													xl={4}
													lg={4}
													md={4}
													xs={4}
													sm={4}
													className={classes.location}
												>
													<div className={classes.locationDiv}>
														<LocationSearchingIcon
															className={classes.fromLocationIcon}
														/>{' '}
														<Typography
															component="div"
															className={classes.locationText}
															//align="center"
														>
															<Box lineHeight={1.3}>
																{' '}
																{capitalize(trip.departure)}
															</Box>{' '}
														</Typography>
													</div>
													<Divider
														orientation="vertical"
														flexItem
														className={classes.divider}
													/>
													<div className={classes.locationDiv}>
														<LocationOnIcon
															className={classes.toLocationIcon}
														/>{' '}
														<Typography
															component="div"
															className={classes.locationText}
															//align="center"
														>
															<Box lineHeight={1.3}>
																{' '}
																{capitalize(trip.destination)}
															</Box>{' '}
														</Typography>
													</div>
												</Grid>
												<Grid item xl={4} lg={4} md={4} xs={2} sm={4}>
													{/* <Typography
                          component="div"
                          className={classes.seatsText}
                          //align="center"
                        >
                          <Box lineHeight={1.3}> Rating</Box>{" "}
                        </Typography>
                        <Rating name="read-only" value={3} readOnly /> */}
												</Grid>
												<Grid
													item
													xl={4}
													lg={4}
													md={4}
													xs={6}
													sm={4}
													className={classes.fareSection}
												>
													<Typography
														component="div"
														className={classes.fareText}
														gutterBottom
														//align="center"
													>
														<Box lineHeight={1.3}>
															{' '}
															Ksh. {formatNum(trip.minPrice)}/=
														</Box>{' '}
													</Typography>
													<Typography
														component="div"
														className={classes.seatsText}
														//align="center"
													>
														<Box lineHeight={1.3}>
															{' '}
															{trip.seats - trip.sold} seats available
														</Box>{' '}
													</Typography>
												</Grid>
											</Grid>
										</CardContent>
									</CardActionArea>
								</Card>
							</motion.div>
						</Grid>
					))
				)}
			</Grid>
		</Container>
	)
}

const mapState = state => ({
	trips: getTrips(state),
	isLoading: getIsLoading(state),
})

export default connect(mapState)(Main)
