import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  colors,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { connect } from "react-redux";
import {
  searchTrips,
  fetchFromOptions,
  fetchToOptions,
} from "../redux/actions/search";
import { api } from "../config/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { getFromOptions, getToOptions, getFromIsLoading, getToIsLoading, getTrips } from "../redux/accessors/search";



const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]:{
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    backgroundColor: '#f79222',
    "&:hover": {
      // backgroundColor: theme.palette.primary.light,
      opacity:'80%'
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  autoComplete: {
    width: "100%",
  },
  subHeader: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  textField: {
    backgroundColor: "#ffffff",
    borderRadius: "5px",
  },
  textInput: {
    "&::placeholder": {
      fontSize: "16px",
      fontWeight: 600,
    },
    fontSize: "16px",
    fontWeight: 600,
  },
  fromIcon: {
    color: colors.green[700],
  },
  label: {
    color: "#ffffff",
    fontWeight: 600,
    marginLeft: 5,
    marginBottom: 10,
  },
  disabledButton: {
    backgroundColor: "#fff",
  },
}));

const Main = ({
  searchTrips,
  fromOptions,
  fetchFromOptions,
  toOptions,
  fetchToOptions,
  isFromLoading,
  isToLoading,
  trips
}) => {
  const classes = useStyles();
  const { params, path } = useRouteMatch();
  const history = useHistory();
  const minDate = new Date();

 
  const [from, setFrom] = useState(null);

  const onFromChange = (e, value) => setFrom(value);

  const [fromInput, setFromInput] = useState("");

  const onFromInputChange = (e, value) => setFromInput(value);

  const [to, setTo] = useState(null);

  const onToChange = (e, value) => setTo(value);

  const [toInput, setToInput] = useState("");

  const onToInputChange = (e, value) => setToInput(value);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const onSelectedDate = (date) => setSelectedDate(date);

  useEffect(() => {
    if (params.from ) setFrom(params.from);
  }, [params]);

  useEffect(() => {
    if (params.to) setTo(params.to);
  }, [params]);

  useEffect(() => {
    console.log('param date format', params.date)
    if (params.date) setSelectedDate(params.date);
  }, [params]);

  const onSubmit = (e) => {
    e.preventDefault();

    path === "/"
      ? history.push(
          `/results/${from}/${to}/${new Date(selectedDate).toDateString()}`
        )
      : searchTrips(api, {
          departure:from,
          destination:to,
          departureDate: selectedDate,
        });
  };

  const onFromOpen = () => fetchFromOptions(api);

  const onToOpen = () => fetchToOptions(api, from);

  const [lastTrips]=useState([])

  // useEffect(() => {
  //   console.log("use effect for search has run");
  //   console.log('params for search',params)
    
  //   const newDate=new Date(selectedDate).toDateString()
  //   console.log('date for search2',newDate)
  //   path !== "/" && params &&
  //     searchTrips(api, {
  //       departure: params.from,
  //       destination: params.to,
  //       departureDate: newDate,
  //     });
  // }, [params,selectedDate, searchTrips, api]);


  useEffect(() => {
    console.log("<<<<<<<<<<<use effect for search has run>>>>>>>>>>>");
    console.log('params for search',params)
    const fetchTrips=()=>{
      const newDate=new Date(selectedDate).toDateString()
     
     path !== "/" && params &&
        searchTrips(api, {
          departure: params.from,
          destination: params.to,
          departureDate: newDate,
        }).then(()=>{
          if(trips===lastTrips) return fetchTrips();
        })
    }

    fetchTrips()
    
  }, [params,selectedDate, searchTrips, api]);

  console.log('From: ', from);
  console.log('From input: ', fromInput);
  console.log('To: ', to)
  console.log('To input: ', toInput);

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2} direction="column">
       
        <Grid item>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="h5" className={classes.label}>
                {" "}
                From
              </Typography>
              <Autocomplete
                value={from}
                onChange={onFromChange}
                inputValue={fromInput}
                onInputChange={onFromInputChange}
                size="small"
                id="from-combo"
                options={fromOptions}
                onOpen={onFromOpen}
                noOptionsText={isFromLoading?'Please wait':'No options available'}
                //getOptionLabel={(option) => option.name}
                //style={{ width: 300 }}
                //onChange={(value, newValue) => onFromChange(value, newValue)}
                renderInput={(params) => (
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    id="from"
                    name="from"
                    placeholder="Pickup Location"
                    size="small"
                    fullWidth
                    {...params}
                    // value={from}
                    // onChange={onFromChange}
                    // InputProps={{
                    //  // classes: { input: classes.textInput },
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <MyLocationIcon className={classes.fromIcon} />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="h5" className={classes.label}>
                {" "}
                To
              </Typography>
              <Autocomplete
                value={to}
                onChange={onToChange}
                inputValue={toInput}
                onInputChange={onToInputChange}
                size="small"
                id="to-combo"
                options={toOptions}
                onOpen={onToOpen}
                noOptionsText={isToLoading?'Please wait':'No options available'}
               // getOptionLabel={(option) => option.name}
                //style={{ width: 300 }}
                // onChange={(value, newValue) => onToChange(value, newValue)}
                renderInput={(params) => (
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    id="to"
                    name="to"
                    placeholder="Destination"
                    fullWidth
                    {...params}
                    // value={from}
                    // onChange={onFromChange}
                    // InputProps={{
                    //  // classes: { input: classes.textInput },
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <MyLocationIcon className={classes.fromIcon} />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <Typography variant="h5" className={classes.label}>
                {" "}
                Departure Time
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.textField}
                  // label="Time"
                  inputVariant="outlined"
                  size="small"
                  value={selectedDate}
                  onChange={onSelectedDate}
                  // minDate={minDate}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              {/*  <div className={classes.label}/> */}
              <Button
                type="submit"
                variant="contained"
                // color="#ec2227"
                fullWidth
                size="medium"
                className={classes.submit}
                onClick={onSubmit}
                disabled={!from || !to || !selectedDate ? true : false}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center"></Grid>
      </Grid>
    </form>
  );
};

const mapState = (state) => ({
  fromOptions: getFromOptions(state),
  toOptions: getToOptions(state),
  isFromLoading:getFromIsLoading(state),
  isToLoading:getToIsLoading(state),
  trips: getTrips(state),
});

const mapDispatch = {
  searchTrips,
  fetchFromOptions,
  fetchToOptions,
};

export default connect(mapState, mapDispatch)(Main);


