import {
  START_LOADING,
  LOAD_REQUEST,
  END_LOADING,
  ADD_PASSENGER,
  PAYMENT_CONFIRMED,
  PAYMENT_PROMPT_SENT,
  TRIP_NEXT_STEP,
  TRIP_PREVIOUS_STEP,
  TRIP_RESET_STEP,
} from "../constants/payment";

const initialState = {
  paymentConfirmed: false,
  paymentPromptSent: false,
  activeStep: 0,
  ticketData: {
    // departure:"Nairobi",
    // destination:"Eldoret",
  },
  isLoading: false,
  passenger: {
    firstName: "",
    lastName: "",
    nationality: "",
    id: "",
    residence: "",
    phone: "",
    hasLuggage: false,
    luggageDescription: "",
  },
  request: "",
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case TRIP_NEXT_STEP:
      return { ...state, activeStep: state.activeStep+1 };

    case TRIP_PREVIOUS_STEP:
      return { ...state, activeStep: state.activeStep-1 };

    case TRIP_RESET_STEP:
      return { ...state, activeStep: 0 };

    case PAYMENT_CONFIRMED:
      return {
        ...state,
        paymentConfirmed: true,
        ticketData: action.ticketData,
      };

    case PAYMENT_PROMPT_SENT:
      return { ...state, paymentPromptSent: true };

    case START_LOADING:
      return { ...state, isLoading: true };

    case LOAD_REQUEST:
      return { ...state, request: action.request };

    case END_LOADING:
      return { ...state, isLoading: false };

    case ADD_PASSENGER:
      return {
        ...state,
        passenger: { ...state.passenger, ...action.passenger },
      };

    default:
      return state;
  }
};

export default main;
