import React from "react";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
   // paddingBottom: theme.spacing(3),
  },
  copyrightText: {
    color: "#ffffff",
  },
}));

const Main = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant="body2"
        align="center"
        gutterBottom
        className={classes.copyrightText}
      >
        {`Copyright © ${new Date().getFullYear()}  |   `}
        <Link color="inherit" href="https://booking.mobitill.com/">
          Mobitill Epesi Booking Platform
        </Link>{" "}
        {"   |   A Product From "}
        <Link color="inherit" rel="noopener noreferrer" target="_blank" href="https://dataintegrated.co.ke/">
          MOBITILL GROUP
        </Link>{" "}
        
        {"   |   Terms and Conditions   |   Privacy Policy"}
      </Typography>
    </div>
  );
};

export default Main;
