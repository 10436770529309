import {
  START_LOADING,
  LOAD_REQUEST,
  END_LOADING,
  ADD_PASSENGER,
  PAYMENT_CONFIRMED,
  PAYMENT_PROMPT_SENT,
  TRIP_NEXT_STEP,
  TRIP_PREVIOUS_STEP,
  TRIP_RESET_STEP
} from "../constants/payment";
import { addSuccessMessage, addErrorMessage, addWarningMessage } from "../actions/notification";

const startLoading = () => ({
  type: START_LOADING,
});

const loadRequest = (request) => ({
  type: LOAD_REQUEST,
  request,
});

const endLoading = () => ({
  type: END_LOADING,
});

const paymentConfirmed = (ticketData) => ({
  type: PAYMENT_CONFIRMED,
  ticketData,
});

 const tripNextStep = () => ({
  type: TRIP_NEXT_STEP,
  
});

 const tripPreviousStep = () => ({
  type: TRIP_PREVIOUS_STEP,
  
});

 const tripResetStep = () => ({
  type: TRIP_RESET_STEP,
  
});

const paymentPromptSent = (isPaymentPromptSent) => ({
  type: PAYMENT_PROMPT_SENT,
  isPaymentPromptSent,
});

//stepper handle steps
export const handleNextStep = () => (dispatch) => {
  dispatch(tripNextStep())
}

export const handlePreviousStep = () => (dispatch) => {
  dispatch(tripPreviousStep())
}

export const handleResetStep = () => (dispatch) => {
  dispatch(tripResetStep())
}

//payment timeout alert
export const showTimeOutAlert = (message) => (dispatch) => {
  dispatch(addWarningMessage(message))
}

// export const sendPaymentPrompt = (api, option, data) => (dispatch) => {
//   dispatch(startLoading());

//   console.log("pay ticket data", data);

//   // const url = option === "mpesa" ? "/mpesa/send" : "/air/send";
//   const url = '/checkout/card'


//   return api({
//     method: "POST",
//     data,
//     url,
//   })
//     .then((response) => {
//       dispatch(endLoading());
//       console.log("payment data response", response);

//       dispatch(loadRequest(response.data.response.data.reference))

//       // option === "mpesa"
//       //   ? dispatch(loadRequest(response.data.response.data.request))
//       //   : dispatch(loadRequest(response.data.response.data.reference));

//       dispatch(paymentPromptSent(true));
//       dispatch(addSuccessMessage(response.data.response.response));
//     })
//     .catch(({ message }) => {
//       dispatch(endLoading());

//       dispatch(addErrorMessage(message?message:"Oh no! Something went wrong"));
//     });
// };

export const sendPaymentPrompt = (api, option, data  ) => async (dispatch) => {
  dispatch(startLoading());

  console.log("Payment request data:", data);

  // Determine the URL for the payment prompt
  const url = "/checkout/card";

  try {
    const response = await api({
      method: "POST",
      url,
      data,
    });

    console.log("Payment response data:", response);

    // Dispatch actions based on the response
    dispatch(endLoading());
    dispatch(loadRequest(response.data.data));
    dispatch(paymentPromptSent(true));
    dispatch(addSuccessMessage("authentication successful"));
  } catch (error) {
    console.error("Payment error:", error);

    // Handle errors and end loading
    dispatch(endLoading());
    dispatch(
      addErrorMessage(
        error?.message || "Oh no! Something went wrong during payment."
      )
    );
  }
};




export const confirmPayment = (api, option, data, booking) => (dispatch) => {
  dispatch(startLoading());
  console.log("confirm pay ticket data 1>data", data);

  console.log("confirm pay ticket data2>booking", booking);

  const url = option === "mpesa" ? "/mpesa/confirm" : "/air/confirm";

  return api({ method: "POST", data, url })
    .then((res) => {
      const url = "/booking/mpesa";

      console.log("confirm pay ticket async 1", res);

      return api({ method: "POST", url, data: { data: booking } });
    })
    .then((res) => {
      dispatch(endLoading());
      console.log(
        "confirm payment response async 2",
        JSON.parse(res.config.data)
      );
      const ticketData = JSON.parse(res.config.data);
      console.log(`my data is: ${ticketData}`);

      dispatch(paymentConfirmed(ticketData.data));

      dispatch(addSuccessMessage("Booking made successfully."));
      dispatch(tripNextStep())
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

export const addPassenger = (passenger) => ({
  type: ADD_PASSENGER,
  passenger,
});
