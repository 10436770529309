import React, { useEffect } from "react";
import SearchForm from "./SearchForm";
import Procedure from "./Procedure";
import AppAd from "./AppAd";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory,useLocation } from "react-router-dom";
import Trips from "./Trips";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    backgroundColor:'#fff',
    // flexDirection:'column',
    // height:'100%',
    // overflowX:'hidden'
  },
}));

const Main = () => {
  const history = useHistory()
  const location = useLocation();
  // Function to get query parameters
  const getQueryParam = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const transactionId = getQueryParam("transactionId");

  // Redirect to /ticket if transactionId is present
  useEffect(() => {
    if (transactionId) {
      history.push(`/ticket?transactionId=${transactionId}`);
    }
  }, [transactionId, history]);

  const classes = useStyles();
  const containerVariants = {
    hidden: {
      opacity: 0.5,
      scale: 1.2,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0, duration: 0.5 },
    },
    exit: {
      x: "-100vw",
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.main
      className={classes.root}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      
      <SearchForm />
      <Trips/>
      <Procedure />
      <AppAd />
    </motion.main>
  );
};

export default Main;
