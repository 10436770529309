import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
	Container,
	Grid,
	Typography,
	Paper,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import {
	makeStyles,
	createMuiTheme,
	ThemeProvider,
} from '@material-ui/core/styles'
import {
	CheckCircle as SuccessIcon,
	Cancel as FailedIcon,
} from '@material-ui/icons'
import moment from 'moment'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(3),
		minHeight: '100vh',
		backgroundColor: '#f4f4f4',
	},
	ticketContainer: {
		maxWidth: 800,
		margin: '0 auto',
		padding: theme.spacing(3),
		borderRadius: theme.spacing(2),
		boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
	},
	successContainer: {
		backgroundColor: 'fff',
		border: `2px solid ${theme.palette.success.main}`,
	},
	failedContainer: {
		backgroundColor: 'fff',
		border: `2px solid ${theme.palette.error.main}`,
	},
	ticketHeader: {
		textAlign: 'center',
		marginBottom: theme.spacing(3),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.spacing(2),
	},
	statusIcon: {
		fontSize: '2.5rem',
	},
	successIcon: {
		color: theme.palette.success.main,
	},
	failedIcon: {
		color: theme.palette.error.main,
	},
	sectionTitle: {
		borderBottom: '2px solid',
		paddingBottom: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	successSectionTitle: {
		borderColor: theme.palette.success.main,
		color: theme.palette.success.main,
	},
	failedSectionTitle: {
		borderColor: theme.palette.error.main,
		color: theme.palette.error.main,
	},
	detailGrid: {
		marginBottom: theme.spacing(2),
	},
	detailLabel: {
		fontWeight: 'bold',
		color: theme.palette.text.secondary,
	},
	successDetailValue: {
		fontWeight: 'bold',
		color: theme.palette.success.dark,
	},
	failedDetailValue: {
		fontWeight: 'bold',
		color: theme.palette.error.dark,
	},
	downloadButton: {
		marginTop: theme.spacing(2),
	},
	successDownloadButton: {
		backgroundColor: theme.palette.success.main,
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
		},
	},
	failedDownloadButton: {
		backgroundColor: 'theme.palette.error.main',
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	loadingContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
}))

const Ticket = () => {
	const classes = useStyles()
	const [ticketData, setTicketData] = useState()
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [openDownloadDialog, setOpenDownloadDialog] = useState(false)
	const location = useLocation()

	const getQueryParam = param => {
		const params = new URLSearchParams(location.search)
		return params.get(param)
	}

	const transactionId = getQueryParam('transactionId')

	useEffect(() => {
		const fetchTicketDetails = async () => {
			try {
				setLoading(true)
				const token =
					'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhNzI5YTI3ZS1hMzI5LTRiYzAtOTYwOC1mZGQxYzM3YWRlZjYiLCJpYXQiOjE1ODAyMTA1MzQsImV4cCI6MTg1MDIxMDUzNH0.OZtT-9gQAOi3RR1pBhR2U9B2RbUMGRG7uGN92J-JO8I'
				const response = await axios.post(
					`${process.env.REACT_APP_BASE_URL}checkout/queryPayment`,
					{
						data: {
							type: 'transactionId',
							value: transactionId,
						},
					},
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
				)
				setTicketData(response.data.data)
			} catch (err) {
				setError('Failed to fetch ticket details. Please try again.')
			} finally {
				setLoading(false)
			}
		}

		fetchTicketDetails()
	}, [transactionId])

	const generateTicketDetails = () => {
		return `
	===================================================
				TRAVEL TICKET DETAILS
	===================================================
	
	BOOKING INFORMATION
	---------------------------------------------------
	Booking Status : ${ticketData.result}
	Transaction ID : ${ticketData.processorTransactionId}
	
	PAYMENT DETAILS
	---------------------------------------------------
	Amount         : ${ticketData.total}
	Payment Mode   : ${ticketData.paymentMode}
	Narration      : ${ticketData.paymentSession.requestNarration}
	Transaction Date: ${ticketData.bookingDetails.date}
	
	PASSENGER DETAILS
	---------------------------------------------------
	Name           : ${ticketData.bookingDetails.passengerName}
	Residence      : ${ticketData.bookingDetails.residence}
	Nationality    : ${ticketData.bookingDetails.nationality}
	Phone          : ${ticketData.bookingDetails.phone}
	
	TRIP DETAILS
	---------------------------------------------------
	Route          : ${ticketData.bookingDetails.route}
	Departure      : ${ticketData.bookingDetails.Departure}
	Destination    : ${ticketData.bookingDetails.destination}
	Vehicle No     : ${ticketData.bookingDetails.vehicleno}
	Sacco Name     : 2KW SACCO
	
	Number of Seats: ${ticketData.bookingDetails.numberOfSeat}
	Departure Time : ${moment(ticketData.bookingDetails.departureTime).format(
		'DD MMM YYYY, hh:mm A',
	)}
	
	===================================================
			THANK YOU FOR YOUR BOOKING
	===================================================
	`
	}

	const downloadTicket = () => {
		// Generate ticket details
		const details = generateTicketDetails()

		// Create a blob with the text content
		const blob = new Blob([details], { type: 'text/plain' })

		// Generate a filename with date and transaction ID
		const filename = `ticket_${moment().format('YYYYMMDD')}_${
			ticketData.processorTransactionId
		}.txt`

		// Create a link element and trigger download
		const link = document.createElement('a')
		link.href = URL.createObjectURL(blob)
		link.download = filename
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)

		// Close the download dialog
		setOpenDownloadDialog(false)
	}

	const handleDownloadClick = () => {
		setOpenDownloadDialog(true)
	}

	const handleCloseDownloadDialog = () => {
		setOpenDownloadDialog(false)
	}

	if (loading) {
		return (
			<div className={classes.loadingContainer}>
				<CircularProgress />
			</div>
		)
	}

	if (error) {
		return (
			<Container className={classes.root}>
				<Typography color="error" align="center">
					{error}
				</Typography>
			</Container>
		)
	}

	// Determine status
	const isSuccess =
		ticketData && ticketData?.result?.toLowerCase() === 'success'

	return (
		<Container className={classes.root}>
			<Paper
				className={`
          ${classes.ticketContainer} 
          ${isSuccess ? classes.successContainer : classes.failedContainer}
        `}
				elevation={3}
			>
				<Typography variant="h4" className={classes.ticketHeader}>
					{isSuccess ? (
						<SuccessIcon
							className={`${classes.statusIcon} ${classes.successIcon}`}
						/>
					) : (
						<FailedIcon
							className={`${classes.statusIcon} ${classes.failedIcon}`}
						/>
					)}
					{isSuccess ? 'Travel Ticket' : 'Booking Failed'}
				</Typography>

				<Grid container spacing={3}>
					{/* Payment Details Column */}
					<Grid item xs={12} md={6}>
						<Typography variant="h6" className={classes.sectionTitle}>
							Payment Details
						</Typography>
						<Grid container className={classes.detailGrid}>
							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Ticket Number:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.ticketNumber}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Transaction Status:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.result}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Transaction Date:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.date}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Payment Mode:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>{ticketData.paymentMode}</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Transaction ID:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.processorTransactionId}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>Amount:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.total}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					{/* Trip Details Column */}
					<Grid item xs={12} md={6}>
						<Typography variant="h6" className={classes.sectionTitle}>
							Trip Details
						</Typography>
						<Grid container className={classes.detailGrid}>
							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Passenger Name:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.passengerName}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Departure Date:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{moment(ticketData.bookingDetails.departureTime).format(
										'DD MMM YYYY, hh:mm A',
									)}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>Route:</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.route}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Departure Station:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>ODEON</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Destination:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.destination}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Vehicle No:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.vehicleno}
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography className={classes.detailLabel}>
									Sacco Name:
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.detailValue}>
									{ticketData.bookingDetails.saccoName}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Button
					variant="contained"
					color="primary"
					fullWidth
					className={classes.downloadButton}
					onClick={downloadTicket}
					disabled={ticketData?.result?.toLowerCase() === 'failed'}
				>
					Download Ticket
				</Button>
			</Paper>

			<Dialog
				open={openDownloadDialog}
				onClose={handleCloseDownloadDialog}
				aria-labelledby="download-dialog-title"
				aria-describedby="download-dialog-description"
			>
				<DialogTitle id="download-dialog-title">
					{'Download Ticket Confirmation'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="download-dialog-description">
						Are you sure you want to download the ticket details? The ticket
						will be saved as a text file with your transaction details.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDownloadDialog} color="primary">
						Cancel
					</Button>
					<Button onClick={downloadTicket} color="primary" autoFocus>
						Download
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	)
}

export default Ticket
