import React, { Fragment } from 'react'
import { AppBar, Toolbar, CssBaseline } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Route } from 'react-router-dom'
import { grey } from '@material-ui/core/colors'
import Logo from '../static/epesiLogoWhite.png'
import NotificationMessage from './NotificationMessage'
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(6),
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: grey[50],
	},
	logo: {
		maxWidth: 160,
	},
	appBar: {
		borderBottom: '0.1px solid #F7FBFE',
		backgroundColor: theme.palette.primary.main,
		[`${theme.breakpoints.between('md', 'md')} and (orientation: landscape)`]: {
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			backgroundColor: theme.palette.primary.main,
		},
	},
}))

const Main = ({ children, ...rest }) => {
	const classes = useStyles()

	return (
		<Route
			{...rest}
			render={() => (
				<Fragment style={{ height: '100%' }}>
					<CssBaseline />

					<NotificationMessage />

					<AppBar position="relative" className={classes.appBar} elevation={0}>
						<Toolbar>
							<a style={{cursor: 'pointer'}} href="/">
								<img className={classes.logo} src={Logo} alt="logo" />
							</a>
						</Toolbar>
					</AppBar>
					{children}
					{/* Footer */}
					<Footer />
					{/* End footer */}
				</Fragment>
			)}
		/>
	)
}

export default Main
