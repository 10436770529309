import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	Avatar,
	Container,
	Grid,
	Paper,
	Typography,
	Zoom,
	Box,
	Card,
	SvgIcon,
} from '@material-ui/core'
import { ReactComponent as GoSvg } from '../../static/svg/go.svg'
import { ReactComponent as BookSvg } from '../../static/svg/book.svg'
import { ReactComponent as PaySvg } from '../../static/svg/pay.svg'
import { motion } from 'framer-motion'

const useStyles = makeStyles(theme => ({
	root: {
		// paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
		backgroundColor: '#fff',
		margin: 'auto',
		width: '100%',
		height: '100%',
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	cardContent: {
		flexGrow: 1,
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(6, 4),
		minHeight: 300,
		WebkitBoxShadow: '0px 3px 26px -12px rgba(196,196,196,1)',
		MozBoxShadow: '0px 3px 26px -12px rgba(196,196,196,1)',
		boxShadow: '0px 3px 26px -12px rgba(196,196,196,1)',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	title: {
		margin: theme.spacing(2, 0),
		fontSize: '28px',
		fontWeight: 700,
	},
	header: {
		fontWeight: 800,
		fontSize: '34px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '28px',
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	svg: {
		height: 60,
		width: 60,
	},
	svgMargin: {
		marginLeft: 10,
	},
	bodyText: {
		fontSize: '16px',
	},
}))

const Main = () => {
	const classes = useStyles()
	const items = [
		{
			icon: <BookSvg className={classes.svg} fill="#f79222" />,
			title: 'Book',
			body: "Take control over your journey than ever before by reserving the seat that's right for you.",
		},
		{
			icon: (
				<PaySvg
					className={classes.svg}
					style={{ marginLeft: 20 }}
					fill="#f79222"
				/>
			),
			title: 'Pay',
			body: 'Pay online via M-pesa or card, stress free, security is guaranteed.',
		},
		{
			icon: (
				<GoSvg
					className={classes.svg}
					style={{ marginLeft: 20 }}
					fill="#f79222"
				/>
			),
			title: 'Go',
			body: 'Get your ticket confirmation from the comfort of your device, enjoy your journey.',
		},
	]

	const [show] = useState(true)

	return (
		<motion.div className={classes.root}>
			<Container className={classes.root}>
				<Grid container spacing={5}>
					<Grid item xs={12} lg={12} sm={12} xl={12} md={12}>
						<Typography
							component="div"
							className={classes.header}
							align="center"
						>
							<Box lineHeight={1.3}>Get a bus in three simple steps</Box>{' '}
						</Typography>
					</Grid>
					{items.map((item, index) => (
						<Zoom
							key={index}
							in={show}
							style={{
								transitionDelay: show ? `${(index + 1) * 500}ms` : '0ms',
							}}
						>
							<Grid item xs={12} lg={4} sm={12} xl={4} md={4}>
								<motion.div whileHover={{ scale: 1.1 }}>
									<Card className={classes.paper} elevation={0}>
										{item.icon}

										<Typography
											component="div"
											className={classes.title}
											align="center"
										>
											<Box lineHeight={1.3}>{item.title}</Box>{' '}
										</Typography>

										<Typography
											component="div"
											className={classes.bodyText}
											align="center"
										>
											<Box lineHeight={1.3}>{item.body}</Box>{' '}
										</Typography>
									</Card>
								</motion.div>
							</Grid>
						</Zoom>
					))}
				</Grid>
			</Container>
		</motion.div>
	)
}

export default Main
