import React from "react";
import {
  Switch,
  useLocation,
  Redirect,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Layout from "./components/Layout";
import Results from "./pages/results";
import Trip from "./pages/trip";
import Home from "./pages/home";
import Ticket from "./pages/ticket";

import NotFound from "./pages/notfound";
import { AnimatePresence } from "framer-motion";
import Trips from "./pages/trips";

const Main = () => {
  const location = useLocation();



  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        <Layout exact path="/" children={<Home />} />
        <Layout exact path="/ticket" children={<Ticket />} />


        <Layout path="/results/:from/:to/:date" children={<Results />} />

        <Layout path="/trip/:id" children={<Trip />} />
        {/* <Layout path="/routes" children={<Trips />} /> */}


        {/* Updated Ticket route */}
        {/* <Route
          path="/"
          render={() => {
            const transactionId = getQueryParam("transactionId");
            return transactionId ? (
              <Ticket transactionId={transactionId} />
            ) : (
              <NotFound />
            );
          }}
        /> */}

        <Route component={NotFound} />
      </Switch>
    </AnimatePresence>
  );
};

export default Main;
