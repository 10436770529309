import React, { useState, useEffect, useCallback, useMemo, forwardRef } from 'react'
import {
    Container,
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
    CardActions,
    Modal,
    makeStyles,
    Box,
} from '@material-ui/core'
import {
    EventSeat as EventSeatIcon,
    Schedule as ScheduleIcon,
} from '@material-ui/icons'
import axios from 'axios'
import RouteTrips from '../trips'


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff',
        padding: theme.spacing(4),
    },
    tripCard: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: theme.spacing(2),
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.03)',
        },
    },
    tripDetails: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    detailItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    modal:{
        height:"50vh"
    }
}))

const Trips = () => {
    const classes = useStyles()
    const [trips, setTrips] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [open, setOpen] = useState(false)
    const [selectedRoute, setSelectedRoute] = useState(null)

    const fetchFeaturedTrips = useCallback(async () => {
        try {
            setLoading(true)
            const token =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhNzI5YTI3ZS1hMzI5LTRiYzAtOTYwOC1mZGQxYzM3YWRlZjYiLCJpYXQiOjE1ODAyMTA1MzQsImV4cCI6MTg1MDIxMDUzNH0.OZtT-9gQAOi3RR1pBhR2U9B2RbUMGRG7uGN92J-JO8I'

            const departureDate = new Date()
            departureDate.setHours(23, 59, 59, 999)

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}routes/seatsSummary?departureDate=${departureDate.toISOString()}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            
            // Only update state if data has changed
            setTrips(prevTrips => {
                const newTrips = response.data.data
                if (JSON.stringify(prevTrips) !== JSON.stringify(newTrips)) {
                    return newTrips
                }
                return prevTrips
            })
        } catch (err) {
            setError('Failed to fetch ticket details. Please try again.')
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchFeaturedTrips()
    }, [fetchFeaturedTrips])

    const handleBook = useCallback((route) => {
        setSelectedRoute(route)
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    // Memoize the trip rendering to prevent unnecessary re-renders
    const tripList = useMemo(() => (
        trips.map((trip, index) => (
            <Grid item xs={12} md={12} key={index}>
                <Card className={classes.tripCard} elevation={3}>
                    <CardContent>
                        <Typography variant="subtitle2" color="textSecondary">
                            {trip.from} to {trip.to}
                        </Typography>

                        <div className={classes.tripDetails}>
                            <div className={classes.detailItem}>
                                <ScheduleIcon color="primary" />
                                <Typography variant="body2">
                                    {trip.trips.length} Trip(s)
                                </Typography>
                            </div>
                            <div className={classes.detailItem}>
                                <EventSeatIcon color="primary" />
                                <Typography variant="body2">
                                    {Number(trip.availableSeats)} Seats
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                    <CardActions>
                        <Button
                            onClick={() => handleBook(trip)}
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            Book Now
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        ))
    ), [trips, classes, handleBook])

    if (loading) return <Typography>Loading...</Typography>
    if (error) return <Typography color="error">{error}</Typography>

    return (
        <Container className={classes.root}>
            <Grid container spacing={3}>
                {tripList}
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="route-details-modal"
                aria-describedby="route-details-description"
            >
                {selectedRoute && (
                    <RouteTrips route={selectedRoute} />
                )}
            </Modal>
        </Container>
    )
}

export default Trips